import React, { useState } from 'react';
import { gradient_color, gray_color2 } from '../../../../../../styles/colors';
import HorizontalBar from '../../../../../ui/horizontalBar/HorizontalBar';
import { useTranslation } from 'react-i18next';
import TooltipWrapper from '../../../../../ui/tooltip/TooltipWrapper';
// qwerty for webinar
import Modal from 'components/ui/modal/Modal';
import Button from 'components/ui/button/Button';

type Props = {
  title: string;
  total: number;
  completed: number;
  uncertainty: number;
  pro: boolean | undefined;
};
const FrameworkProgressBar = ({ title, total, completed, uncertainty, pro }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });
  // qwerty for webinar
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  return (
    <div
      className='flex items-center justify-between gap-1'
      {...(pro ? { onClick: toggleModal } : {})}>
      <div
        // qwerty for webinar
        className={`font-12 weight-${pro ? 400 : 600}`}
        style={{
          whiteSpace: 'nowrap',
          color: pro ? '#6e86f7' : undefined,
          cursor: pro ? 'pointer' : 'auto'
        }}>
        {title}
      </div>
      {/* qwerty for webinar */}
      {pro ? (
        <div
          className='h-full'
          style={{
            backgroundColor: '#e7e7f9',
            padding: '0 5px',
            borderRadius: '4px',
            cursor: 'pointer'
          }}>
          <img src='/images/icons/multiStarPurple.svg' alt='pro' />
        </div>
      ) : null}
      <div className='flex items-center gap-2' style={{ width: '80%' }}>
        <TooltipWrapper
          size='small'
          text={
            <ul style={{ margin: 0, paddingInlineStart: '0.5rem' }}>
              <li>
                {t('barTooltip.withData', {
                  total: Math.round(completed)
                })}
              </li>
              <li>
                {t('barTooltip.pending', {
                  total: Math.round(total - completed)
                })}
              </li>
              {/* <li>
                {t('barTooltip.uncertainty', { 
                  uncertainty
                })}
              </li> */}
            </ul>
          }
          style={{ width: '100%' }}>
          <HorizontalBar
            total={100}
            values={[
              {
                value: Math.round((completed / total) * 100),
                // qwerty for webinar
                color: pro ? '#ced2fa' : gradient_color
              }
            ]}
            style={{ borderRadius: '8px', marginLeft: 'auto', background: gray_color2 }}
          />
        </TooltipWrapper>
        <span
          className='font-12 weight-400'
          // qwerty for webinar
          style={{ whiteSpace: 'nowrap', textAlign: 'right', color: pro ? '#6e86f7' : undefined }}>
          {total === 0 ? 0 : Math.round((completed / total) * 100)}% {t('withData')}
        </span>
      </div>
      {/* qwerty for webinar */}
      <Modal show={showModal} onClose={toggleModal}>
        <img alt='placeholder modal' src='/images/placeholders/faltaPoco.png' className='mb-8' />
        <Button lookAndFeel='primary' onClick={toggleModal}>
          Entendido!
        </Button>
      </Modal>
    </div>
  );
};

export default FrameworkProgressBar;
