import NuvoImporter from 'components/ui/nuvoImporter/NuvoImporter';
import { LanguageType, SettingsAPI } from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import { basicNuvoStyle } from 'styles/nuvo';
import { MAX_NUVO_ENTRIES, TEMPLATE_FILE_NAME } from './constants';
import { useColumns } from './useColumns';
import Button from 'components/ui/button/Button';
import { updateOrCreateAnonymousSuppliers } from 'services/api/purchaseSuppliers';
import { PurchaseFramework } from 'utils/mapToPurchaseFramework';

type Props = {
  org_id?: string;
};

export const AnonymousSuppliers: React.FC<Props> = ({ org_id }) => {
  const { i18n } = useTranslation();

  const { columns } = useColumns();

  const settings: SettingsAPI = {
    multipleFileUpload: true,
    language: i18n.resolvedLanguage as LanguageType,
    style: basicNuvoStyle,
    automaticHeaderDetection: true,
    maxEntries: MAX_NUVO_ENTRIES,
    identifier: TEMPLATE_FILE_NAME,
    columns
  };

  if (!org_id) return <Button text='Subir proveedores anónimos' lookAndFeel='disabled' disabled />;

  return (
    <NuvoImporter
      btnI18nKey='Subir proveedores anónimos'
      settings={settings}
      lookAndFeel='secondary'
      onResults={async (results) => {
        const adaptedResults =
          results.map((result) => {
            const code = result.code?.toString().split('-')[0];

            if (!code || !result.code_type || !result.name) throw new Error('Invalid data');

            return {
              business_name: result.name as string,
              country: result.country as string,
              framework: result.code_type as PurchaseFramework.SIC | PurchaseFramework.CNAE,
              code
            };
          }) ?? [];

        const response = await updateOrCreateAnonymousSuppliers(adaptedResults);

        console.log(response);
      }}
    />
  );
};
