import { useContext, useEffect, useState } from 'react';
import { FilterDatesContext } from '../../../../context/filterDatesContext';
import {
  getDatasourceProgress,
  getOrganizationFrameworkStatus
} from '../../../../services/api/esg';
import moment from 'moment';
import {
  EsgCategoryCompleteness,
  EsgFrameworkCompleteness
} from '../../../../types/entities/esgMetrics';
import makeRequestsInBatches from '../../../../utils/makeRequestInBatches';
import { localStorageSetItem } from '../../../../utils/localStorage';

const useGetData = () => {
  const { startDate, endDate } = useContext(FilterDatesContext);

  const [organizationESGStatus, setOrganizationESGStatus] = useState<{
    data: EsgCategoryCompleteness[];
    loading: boolean;
  }>({
    data: [],
    loading: false
  });
  const [organizationFrameworkStatus, setOrganizationFrameworkStatus] = useState<{
    data: EsgFrameworkCompleteness[];
    loading: boolean;
  }>({ data: [], loading: false });

  const fetchData = async () => {
    const startDateParsed = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const endDateParsed = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

    try {
      const requests = [];
      if (!organizationESGStatus.loading) {
        setOrganizationESGStatus({ data: [], loading: true });
        requests.push(() => getDatasourceProgress(startDateParsed, endDateParsed, true));
      }

      if (!organizationFrameworkStatus.loading) {
        setOrganizationFrameworkStatus({ data: [], loading: true });
        requests.push(() => getOrganizationFrameworkStatus(startDateParsed, endDateParsed, true));
      }

      // Execute requests in batches
      const [responseDataSourceProgress, responseFrameworkProgress] = await makeRequestsInBatches(
        requests
      );

      // Safely set the data or fallback to an empty array if the response is undefined
      setOrganizationESGStatus({
        data: responseDataSourceProgress?.data ?? [],
        loading: false
      });

      setOrganizationFrameworkStatus({
        data: responseFrameworkProgress?.data ?? [],
        loading: false
      });
    } catch (error) {
      console.error('Error fetching data:', error);

      // Set loading to false even if the request fails, and maintain the empty state
      setOrganizationESGStatus({ data: [], loading: false });
      setOrganizationFrameworkStatus({ data: [], loading: false });
    }
  };

  useEffect(() => {
    // Parse the startDate and endDate using moment
    const start = moment(startDate, 'DD/MM/YYYY');
    const end = moment(endDate, 'DD/MM/YYYY');

    // Check if startDate is the first day of the year and endDate is the last day of the year
    const isSameYear = start.isSame(end, 'year');
    const isFirstDayOfYear = start.isSame(moment(start).startOf('year'), 'day');
    const isLastDayOfYear = end.isSame(moment(end).endOf('year'), 'day');

    if (!(isSameYear && isFirstDayOfYear && isLastDayOfYear)) {
      // If not, set startDate to January 1st and endDate to December 31st of the current year
      const firstDayOfYear = moment().startOf('year').format('DD/MM/YYYY');
      const lastDayOfYear = moment().endOf('year').format('DD/MM/YYYY');

      // Update session storage with the new dates
      localStorageSetItem('start_date_dashboard', firstDayOfYear);
      localStorageSetItem('end_date_dashboard', lastDayOfYear);
    } else {
      // If the dates are valid, fetch the data
      fetchData();
    }
  }, [startDate, endDate]);

  return {
    organizationESGStatus,
    organizationFrameworkStatus
  };
};

export default useGetData;
