import { PieChart } from 'react-minimal-pie-chart';
import { PropsWithDefaults } from 'react-minimal-pie-chart/types/Chart/Chart';
import { BaseDataEntry } from 'react-minimal-pie-chart/types/commonTypes';
import numberToDecimal from '../../../utils/numberToDecimal';
import formatNumber from '../../../utils/formatNumber';
import './styles.scss';
interface Props extends Partial<PropsWithDefaults<BaseDataEntry>> {
  data: BaseDataEntry[];
  total: number;
  unit: string;
  width: string;
}
const PieChartCustom = ({ data, total, unit, width, ...rest }: Props) => {
  const renderTotalInformation = () => {
    let fontSize = 20;
    const maxDigits = 5;
    if (numberToDecimal(total).toString().length > maxDigits) {
      fontSize = 20 - (numberToDecimal(total).toString().length - maxDigits) - 2;
    }
    return (
      <div className='total-information'>
        <div
          className={`total-value highlight-text-color weight-600`}
          style={{ fontSize: `${fontSize}px` }}>
          {formatNumber(numberToDecimal(total))}
        </div>
        <span className='total-unit on-light-text-color font-12 weight-400'>{unit}</span>
      </div>
    );
  };
  return (
    <div className='piechart-wrapper round on-card-gray-bg-color'>
      <div className='piechart-progress-bar' style={{ width }}>
        <PieChart // your data
          data={data}
          {...rest}
          // width and height of the view box
          viewBoxSize={[100, 100]}
          lineWidth={24}
          totalValue={100}
          rounded
          animate></PieChart>
        {renderTotalInformation()}
      </div>
    </div>
  );
};

export default PieChartCustom;
