import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../../constants/routes';
import { EsgCategoryCompleteness } from '../../../../../types/entities/esgMetrics';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import Card from '../../../dashboardCommon/card/Card';
import DataCategoryCTA from './components/DataCategoryCTA';

type Props = {
  data: EsgCategoryCompleteness[];
  loading: boolean;
};
const DataProgress = ({ data, loading }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'controlPanel.dataProgress' });

  if (loading) {
    return <LoaderCard />;
  }

  const sortData = (data: EsgCategoryCompleteness[]) => {
    return data.map((item) => {
      return {
        ...item,
        id: item.category === 'environmental' ? 1 : item.category === 'social' ? 2 : 3
      };
    });
  };

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <div
          className='on-card-gray-bg-color pt-4 pb-4 pr-6 pl-6 flex flex-col gap-4 rounded-8'
          style={{ height: 'fit-content' }}>
          {sortData(data)
            .sort((a, b) => a.id - b.id)
            .filter((item) => item.category !== 'general_information')
            .map((elem) => (
              <DataCategoryCTA
                key={elem.category}
                title={t(elem.category)}
                total={elem.total}
                completed={elem.completed}
                route={ROUTES.MEASURE}
              />
            ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default DataProgress;
