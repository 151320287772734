import React, { useEffect, useState } from 'react';
import Icon from '../../../ui/icon/Icon';
import Button from '../../../ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useFeatureFlags } from '../../../../customHooks/useFeatureFlags';
import { localStorageGetItem, localStorageSetItem } from '../../../../utils/localStorage';

const SlowPerformanceBanner = () => {
  const { t } = useTranslation();
  const flags = useFeatureFlags();

  const [showPerformanceNotification, setShowPerformanceNotification] = useState(true);

  const performanceNotificationClosedLocalStorage = localStorageGetItem(
    'performanceNotificationClosed'
  );

  useEffect(() => {
    if (performanceNotificationClosedLocalStorage) {
      setShowPerformanceNotification(false);
    }
  }, [performanceNotificationClosedLocalStorage]);

  const handleHideSlowPerformanceBanner = () => {
    setShowPerformanceNotification(false);
    localStorageSetItem('performanceNotificationClosed', true);
  };

  if (!flags?.showPerformanceNotification || !showPerformanceNotification) {
    return null;
  }

  return (
    <div className='w-full border-primary border-solid border-1 rounded-8 py-4 px-6 bg-neutral-white mb-6 border-box mt-10'>
      <div className='flex gap-x-3'>
        <Icon icon='information' color='gradient' size='large' />
        <div className='flex-col justify-between gap-y-6'>
          <div className='flex-col gap-y-2'>
            <p className='font-headings-h4-sb m-0'>{t('slowPerformanceBanner.title')}</p>
            <p className='font-body-b1-r m-0'>{t('slowPerformanceBanner.subtitle')}</p>
          </div>
          <Button
            lookAndFeel='secondary'
            size='small'
            style={{
              width: 'fit-content'
            }}
            onClick={handleHideSlowPerformanceBanner}>
            {t('slowPerformanceBanner.button')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SlowPerformanceBanner;
