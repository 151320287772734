import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import Icon from '../../ui/icon/Icon';
import Label from '../../ui/label/Label';
import TableComponent from '../../ui/table/Table';
import Card from '../dashboardCommon/card/Card';
import EsgCard from './components/EsgCard';

import InputWrapperPlain from '../../ui/formComponents2/inputUtils/inputWrapperPlain/InputWrapperPlain';
import Select from '../../ui/formComponents2/select/Select';
import LoaderCard from '../../ui/loaders/loaderCard/LoaderCard';
import ImpactFacilityAndOrg from './components/ImpactFacilityAndOrg';
import useCompanies from './hooks/useCompanies';
import { useGetIntensityMetrics } from './hooks/useGetIntensityMetrics';
import useIntensityMetrics from './hooks/useIntensityMetrics';
import usePreviusYearComparison from './hooks/usePreviusYearComparison';
import { mockedData, visualizationMode } from './mockedData';
import './styles.scss';
import { parseDataForSelectIntensityMetric } from './utils/utils';
import { StepCarouselType } from '../../../types/stepCarousel';

type Props = {
  stepSelected?: StepCarouselType;
};
export const DashboardEsg = ({ stepSelected }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: intensityMetricData, isLoading } = useGetIntensityMetrics();

  const {
    previusYearComparisonData,
    impactFacilityAndOrgData,
    isLoadingPreviusYearComparison,
    previusYearComparisonIntensityMetric,
    previusYearComparisonESGmetric,
    setPreviusYearComparisonESGmetric,
    setPreviusYearComparisonIntensityMetric,
    unit
  } = usePreviusYearComparison();

  const {
    companyOrFacility,
    companyOrFacilityEsgMetric,
    setCompanyOrFacility,
    setCompanyOrFacilityEsgMetric,
    impactsData,
    isLoadingImpacts,
    columnsImpacts,
    unit: impactUnit
  } = useCompanies();

  const {
    metricsData,
    isLoadingMetrics,
    impactPerMetricIntensityMetric,
    absoluteOrIntensity,
    setAbsoluteOrIntensity,
    setImpactPerMetricIntensityMetric
  } = useIntensityMetrics();

  const renderMockedData =
    previusYearComparisonIntensityMetric.id === '' && previusYearComparisonESGmetric.id === '';

  const options = !isLoading ? parseDataForSelectIntensityMetric(intensityMetricData) : [];

  const titleBarChart = (
    <div className='flex justify-between items-center width-100'>
      <div className='width-75'>
        {t('dashboard.previousYearComparisonEsg', { unit: unit ?? '-' })}
      </div>

      <InputWrapperPlain iconV2='filter'>
        <Select
          onChange={setPreviusYearComparisonIntensityMetric}
          value={previusYearComparisonIntensityMetric}
          options={options}
          placeholder={t('dashboard.phIntensityMetric')}
        />
        <Select
          value={previusYearComparisonESGmetric}
          onChange={setPreviusYearComparisonESGmetric}
          options={previusYearComparisonData?.metrics || []}
          placeholder={t('dashboard.phEsgMetric')}
          disabled={previusYearComparisonIntensityMetric.id === '' ? true : false}
        />
      </InputWrapperPlain>
    </div>
  );

  const titleImpactCompanyOrFacility = (
    <div className='flex justify-between items-center width-100'>
      <div className='width-75'>
        {t('dashboard.impactPerCompanyOrFacilityEsg', { unit: impactUnit })}
      </div>
      <div className='width-45'>
        <InputWrapperPlain iconV2='filter'>
          <Select
            onChange={setCompanyOrFacility}
            value={companyOrFacility}
            options={visualizationMode.map((item) => ({
              id: item.id,
              name: t(`dashboard.${item.name}`)
            }))}
          />
          <Select
            value={companyOrFacilityEsgMetric}
            onChange={setCompanyOrFacilityEsgMetric}
            options={previusYearComparisonData?.metrics || []}
            placeholder={t('dashboard.phEsgMetric')}
          />
        </InputWrapperPlain>
      </div>
    </div>
  );

  console.log('stepSelected', stepSelected);

  return (
    <>
      {!isLoading && intensityMetricData?.items?.length === 0 && (
        <Label lookAndFeel='error' style={{ marginTop: '1rem' }}>
          <Icon icon='warning' color='error' />
          {t('dashboard.noDataIntensityMetrics')}
          <span
            className='pointer m-0-2 underline'
            onClick={() => {
              navigate(ROUTES.CUSTOM_INTENSITY_METRICS + '?open=create');
            }}>
            {t('general.here')}
          </span>
        </Label>
      )}

      <div
        className='dashboard__body-esg'
        style={{
          gridTemplateColumns: stepSelected?.id === 'socialAndGovernance' ? '1fr' : '1fr 1fr',
          gridTemplateRows:
            stepSelected?.id === 'socialAndGovernance'
              ? '1fr'
              : 'repeat(2, min(40vh - 0.25rem, 30rem))'
        }}>
        {isLoadingPreviusYearComparison && stepSelected?.id !== 'socialAndGovernance' && (
          <LoaderCard />
        )}
        {!isLoadingPreviusYearComparison && stepSelected?.id !== 'socialAndGovernance' && (
          <Card className='impact-previus-year-comparison'>
            <>
              <Card.Header title={titleBarChart} />
              <Card.Body className='mb-1'>
                <ImpactFacilityAndOrg
                  data={renderMockedData ? mockedData : impactFacilityAndOrgData}
                  renderMockedData={renderMockedData}
                />
              </Card.Body>
            </>
          </Card>
        )}

        {stepSelected?.id !== 'socialAndGovernance' && (
          <Card className='impact-facility-org'>
            <>
              <Card.Header title={titleImpactCompanyOrFacility} />
              <Card.Body className='infinite-scroll-container overflow-scroll'>
                <div className='infinite-scroll-wrapper'>
                  <TableComponent
                    data={impactsData ?? []}
                    loading={isLoadingImpacts}
                    columns={columnsImpacts}
                    style={{ marginTop: '2rem' }}
                  />
                </div>
              </Card.Body>
            </>
          </Card>
        )}

        <EsgCard
          data={metricsData?.parsedData}
          loading={isLoadingMetrics}
          setAbsoluteOrIntensity={setAbsoluteOrIntensity}
          absoluteOrIntensity={absoluteOrIntensity}
          impactPerMetricIntensityMetric={impactPerMetricIntensityMetric}
          setImpactPerMetricIntensityMetric={setImpactPerMetricIntensityMetric}
          intensityMetricData={intensityMetricData ?? []}
          stepSelected={stepSelected}
        />
      </div>
    </>
  );
};
