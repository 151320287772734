import { EsgMetricsEnhanced } from '../types/entities/esgMetrics';

export const parseDatapointToBackend = (data: EsgMetricsEnhanced): any => {
  let value: string | number | boolean | File | undefined | null = data.value;

  let value_unit_id: string | undefined | null = data.value_unit_id;

  if (value === undefined || value === '' || value === null) {
    value = null;
    value_unit_id = null;
  }
  return {
    value,
    description: data.description,
    start_date: data.start_date.replaceAll('/', '-'),
    end_date: data.end_date.replaceAll('/', '-'),
    data_point_id: data.id,
    relevance: data.relevance,
    file_urls: data.file_urls ?? [],
    value_unit_id: value_unit_id
  };
};
