import { useEffect } from 'react';

const buttonOpenSelector = {
  primary: '.extra-scope-160ji46',
  secondary: '.extra-scope-13evd8c',
  link: '.extra-scope-izdlnm'
};

type Props = {
  onClick: () => void;
  lookAndFeel?: 'primary' | 'secondary' | 'link';
};

const useNuvoButton = ({ onClick, lookAndFeel = 'primary' }: Props) => {
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const buttonOpen = document.querySelector(buttonOpenSelector[lookAndFeel]);

      if (!buttonOpen) return;

      buttonOpen.addEventListener('click', onClick, true);
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);
};

export default useNuvoButton;
