export const ROUTES = {
  REGISTER: '/register',
  REGISTER_INVITED: '/register-invited',
  REGISTER_COMPANY: '/register-company',
  ONBOARDING: '/onboarding',
  CALLBACK: '/callback',
  EMAIL_SENT: '/email-sent',
  LOGIN: '/login',
  SIGNIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  CREATE_PASSWORD: '/create-password',
  MEASURE: '/measure',
  MEASURE_FACILITIES: '/measure/facilities',
  MEASURE_VEHICLES: '/measure/vehicles',
  MEASURE_LOGISTICS: '/measure/logistics',
  MEASURE_EVENTS: '/measure/events',
  MEASURE_ACTIVITY_EMPLOYEES: '/measure/employees',
  MEASURE_ACTIVITY_WASTE: '/measure/waste',
  MEASURE_ACTIVITY_BUSINESS_TRAVELS: '/measure/travels',
  MEASURE_ACTIVITY_PURCHASES: '/measure/purchases',
  MEASURE_ACTIVITY_SOCIAL_AND_GOVERNANCE: '/measure/social-and-governance',
  MEASURE_FUNDS: '/measure/funds',
  MEASURE_DATADIS: '/measure/datadis',
  MEASURE_HUBS: '/measure/hubs',
  MEASURE_UPLOADED_FILES: '/measure/uploaded-files',
  MEASURE_ACTIVITY_USE_AND_END_OF_LIFE: '/measure/use-and-end-of-life',
  MEASURE_ESG_METRICS: '/measure/esg-metrics',
  MEASURE_ESG_METRICS_INFOGRAPHY: '/measure/esg-metrics/infography',
  IMPROVE: '/improve',
  IMPROVE_DASHBOARD: '/dashboard/total',
  IMPROVE_DASHBOARD_TOTAL: '/dashboard/total',
  IMPROVE_DASHBOARD_INVESTMENT: '/dashboard/investment',
  IMPROVE_DASHBOARD_GROUPING: '/dashboard/grouping',
  IMPROVE_DASHBOARD_LOGISTICS: '/dashboard/logistics',
  IMPROVE_DASHBOARD_DATAPOINTS: '/dashboard/datapoints',
  IMPROVE_DASHBOARD_CONTROL_PANEL: '/dashboard/control-panel',
  IMPROVE_OFFSET: '/improve/offset',
  IMPROVE_RECOMMENDATIONS: '/improve/recommendations',
  IMPROVE_AVOIDED_EMISSIONS: '/improve/avoided-emissions',
  IMPROVE_MODELING: '/improve/modeling',
  IMPROVE_SBTI: '/improve/sbti',
  IMPROVE_LEAN_AND_GREEN: '/improve/lean-and-green',
  COMMUNICATE_CSRD_REPORTING: '/communicate/csrd-reporting',
  COMMUNICATE_CDP_REPORTING: '/communicate/cdp-reporting',
  COMMUNICATE_ECOVADIS_REPORTING: '/communicate/ecovadis-reporting',
  COMMUNICATE_14001: '/communicate/14001',
  COMMUNICATE: '/communicate',
  COMMUNICATE_GHG: '/communicate/ghg',
  COMMUNICATE_GLEC: '/communicate/glec',
  COMMUNICATE_WEB: '/communicate/web',
  COMMUNICATE_DOWNLOADABLES: '/communicate/downloadables',
  COMMUNICATE_MITECO: '/communicate/MITECO',
  PROFILE: '/profile',
  MEMBERS: '/members',
  PLANS: '/plans',
  INVOICING: '/invoicing',
  TODOS: '/tasks',
  ADMIN: '/admin',
  ADMIN_ORGANIZATIONS: '/admin/organizations',
  ADMIN_USERS: '/admin/users',
  ADMIN_PURCHASES: '/admin/purchases',
  ADMIN_PURCHASES_CODE_MANAGEMENT: '/admin/purchases/code-management',
  ADMIN_EMAILS: '/admin/emails',
  ADMIN_SHIPMENTS: '/admin/shipments',
  ADMIN_BUSINESS_TRAVEL: '/admin/travels',
  ADMIN_LCA: '/admin/lca',
  ADMIN_LCA_SCENARIOS: '/admin/scenarios_lca',
  ADMIN_INVOFOX: '/admin/invofox',
  SNIPPET: '/communicate/snippet',
  MEASURE_ACTIVITY_SHIPMENTS: '/measure/shipments',
  MEASURE_COMPANIES: '/measure/companies',
  CREATE_ORGANIZATION_HOLDING: '/create-organization-holding',
  EMPLOYEE_FORM: '/employee-form',
  EMPLOYEE_FORM_QR: '/employee-form-qr',
  BUSINESS_TRAVEL_FORM: '/business-travel-form',
  BUDGET_CALCULATOR: '/budget-calculator',
  BOOK_MEETING: '/book-meeting',
  ORGANIZATION_SELECTOR: '/organization-selector',
  CUSTOMIZE_MAIN_PAGE: '/customize',
  CUSTOM_EMISSION_FACTORS: '/customize/emission-factors',
  CUSTOM_INTENSITY_METRICS: '/customize/intensity-metrics',
  CUSTOM_GROUPING: '/customize/grouping',
  CUSTOM_METRICS: '/customize/metrics',
  SOMETHING_WENT_WRONG: '/something-went-wrong',
  NOT_FOUND: '/not-found',
  LCA: '/lca',
  LCA_PORTFOLIO: '/lca/portfolio',
  LCA_SCENARIOS: '/lca/scenarios',
  LCA_CALENDLY_MEETING: '/lca/calendly-meeting',
  ADMIN_SNIPPET_UPLOAD: '/admin/snippet-upload',
  ADMIN_VEHICLE_CONSUMPTIONS: '/admin/vehicle-consumptions',
  ADMIN_TASKS: '/admin/tasks',
  DATA_PROGRESS: '/data-progress'
};

export const PREMIUM_ROUTES = [
  ROUTES.MEASURE_ACTIVITY_PURCHASES,
  ROUTES.COMMUNICATE_GHG,
  ROUTES.COMMUNICATE_WEB,
  ROUTES.TODOS,
  ROUTES.SNIPPET,
  ROUTES.CUSTOM_EMISSION_FACTORS,
  ROUTES.CUSTOM_INTENSITY_METRICS,
  ROUTES.CUSTOMIZE_MAIN_PAGE,
  ROUTES.MEASURE_EVENTS,
  '?showOrgDescriptionModalGHG=true',
  '?showOrgDescriptionModalDcycle=true',
  '?showOrgDescriptionModalSnippet=true',
  '?showOrgDescriptionModalPai=true'
];

export const PAID_ROUTES = [
  ROUTES.COMMUNICATE_14001,
  ROUTES.COMMUNICATE_CSRD_REPORTING,
  ROUTES.IMPROVE_LEAN_AND_GREEN,
  ROUTES.COMMUNICATE_CDP_REPORTING,
  ROUTES.COMMUNICATE_ECOVADIS_REPORTING
];

export const ESG_ROUTES = [
  ROUTES.IMPROVE_DASHBOARD,
  ROUTES.IMPROVE_DASHBOARD_TOTAL,
  ROUTES.IMPROVE_DASHBOARD_INVESTMENT,
  ROUTES.IMPROVE_DASHBOARD_GROUPING,
  ROUTES.IMPROVE_DASHBOARD_LOGISTICS,
  ROUTES.IMPROVE_DASHBOARD_DATAPOINTS
];
