import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { editElement } from './utils';

export const useMutateCache = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();
  const edit = <T extends { id: string }>(newValue: T, key: keyof T & string) =>
    queryClient.setQueryData(queryKey, editElement(newValue, key));

  return { edit };
};
