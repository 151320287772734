import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect, useState } from 'react';
import { groupByCategoryHolding as groupByCategoryHoldingFunction } from '../../../../constants/groupByCategoryFunction';
import { FilterDatesContext } from '../../../../context/filterDatesContext';
import { UserContext } from '../../../../context/userContext';
import useSelectedOrganization from '../../../../customHooks/useSelectedOrganization';
import { getMatrix } from '../../../../services/api/matrices';
import { Holding } from '../../../../types/entities/holdings';
import { OrganizationShort } from '../../../../types/entities/organization';
import { GroupDataMode } from '../../../../types/entitiesEnums/report';
import { convertStringToDate } from '../../../../utils/convertDates';
import makeRequestsInBatches from '../../../../utils/makeRequestInBatches';
import { getUniqueItemsByProperties } from '../../../../utils/removeDuplicates';

type Params = {
  thisYearData: Holding & { startDate: Date; endDate: Date };
  lastYearData: Holding & { startDate: Date; endDate: Date };
};

const adapterDashboardYear = ({ thisYearData, lastYearData }: Params) => {
  const parsedYearComparisonData: YearComparisonData[] = [
    {
      start_date: thisYearData.startDate.getTime(),
      end_date: thisYearData.endDate.getTime(),
      scope_1: 0,
      scope_2: 0,
      scope_3: 0
    },
    {
      start_date: lastYearData.startDate.getTime(),
      end_date: lastYearData.endDate.getTime(),
      scope_1: 0,
      scope_2: 0,
      scope_3: 0
    }
  ];

  thisYearData.parent_co2e?.forEach((elem) => {
    if (elem.scope === 1) {
      parsedYearComparisonData[0].scope_1 += elem.total_emissions;
    }
    if (elem.scope === 2) {
      parsedYearComparisonData[0].scope_2 += elem.total_emissions;
    }
    if (elem.scope === 3) {
      parsedYearComparisonData[0].scope_3 += elem.total_emissions;
    }
  });

  thisYearData.childs_info.forEach((child) => {
    child.co2e.forEach((elem) => {
      if (elem.scope === 1) {
        parsedYearComparisonData[0].scope_1 += elem.total_emissions;
      }
      if (elem.scope === 2) {
        parsedYearComparisonData[0].scope_2 += elem.total_emissions;
      }
      if (elem.scope === 3) {
        parsedYearComparisonData[0].scope_3 += elem.total_emissions;
      }
    });
  });

  lastYearData.parent_co2e?.forEach((elem) => {
    if (elem.scope === 1) {
      parsedYearComparisonData[1].scope_1 += elem.total_emissions;
    }
    if (elem.scope === 2) {
      parsedYearComparisonData[1].scope_2 += elem.total_emissions;
    }
    if (elem.scope === 3) {
      parsedYearComparisonData[1].scope_3 += elem.total_emissions;
    }
  });

  lastYearData.childs_info.forEach((child) => {
    child.co2e.forEach((elem) => {
      if (elem.scope === 1) {
        parsedYearComparisonData[1].scope_1 += elem.total_emissions;
      }
      if (elem.scope === 2) {
        parsedYearComparisonData[1].scope_2 += elem.total_emissions;
      }
      if (elem.scope === 3) {
        parsedYearComparisonData[1].scope_3 += elem.total_emissions;
      }
    });
  });

  return parsedYearComparisonData;
};

export const useTotalDashboard = () => {
  const { flags } = useFlags();

  const user = useContext(UserContext);
  const { startDate, endDate, dateError } = useContext(FilterDatesContext);

  const organizationFound = useSelectedOrganization();

  const groupDataMode = GroupDataMode.TOTAL;

  const [loading, setLoading] = useState(true);

  const [disabled, setDisabled] = useState(false);
  const [dataHolding, setDataHolding] = useState<Holding>();
  const [dataScopes, setDataScopes] = useState<ScopeData[]>([]);
  const [dataCategories, setDataCategories] = useState<CategoryData[]>([]);
  const [dataYearComparison, setDataYearComparison] = useState<YearComparisonData[]>([]);
  const [totalCo2, setTotalCo2] = useState<number>(0);

  useEffect(() => {
    if (!user || dateError) return;
    requestUserInfoHoldings();
  }, [startDate, endDate, groupDataMode]);

  const fetchMatrix = () =>
    getMatrix(
      user?.selectedOrganization ?? '',
      convertStringToDate(startDate),
      convertStringToDate(endDate),
      groupDataMode,
      true
    );

  const fetchMatrixPreviousYear = () => {
    const startDatePreviousYear = convertStringToDate(startDate);
    startDatePreviousYear.setFullYear(startDatePreviousYear.getFullYear() - 1);

    const endDatePreviousYear = convertStringToDate(endDate);
    endDatePreviousYear.setFullYear(endDatePreviousYear.getFullYear() - 1);

    return getMatrix(
      user?.selectedOrganization ?? '',
      startDatePreviousYear,
      endDatePreviousYear,
      groupDataMode,
      true
    );
  };

  // HOLDINGS
  const requestUserInfoHoldings = async () => {
    if (!user?.selectedOrganization) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requests: any[] = [fetchMatrix, fetchMatrixPreviousYear];

    setLoading(true);
    const responses = await makeRequestsInBatches(requests);
    setLoading(false);

    const dataHoldingResponse = {
      ...(responses[0]?.data as Holding),
      startDate: convertStringToDate(startDate),
      endDate: convertStringToDate(endDate)
    };
    const startDatePreviousYear = convertStringToDate(startDate);
    startDatePreviousYear.setFullYear(startDatePreviousYear.getFullYear() - 1);

    const endDatePreviousYear = convertStringToDate(endDate);
    endDatePreviousYear.setFullYear(endDatePreviousYear.getFullYear() - 1);
    const yearComparisonResponse = {
      ...(responses[1].data as Holding),
      startDate: startDatePreviousYear,
      endDate: endDatePreviousYear
    };

    const parsedYearComparisonData = adapterDashboardYear({
      thisYearData: dataHoldingResponse,
      lastYearData: yearComparisonResponse
    });

    setDataYearComparison(parsedYearComparisonData);

    setDisabled(
      dataHoldingResponse?.childs_info.length === 0 &&
        dataHoldingResponse?.parent_co2e?.length === 0
    );

    if (!dataHoldingResponse) return;

    const parsedDataHoldingResponse = {
      ...dataHoldingResponse,
      childs_info: getUniqueItemsByProperties(dataHoldingResponse.childs_info, ['org_id'])
    };
    setDataHolding(parsedDataHoldingResponse);
    const groupByCategoryHolding = groupByCategoryHoldingFunction(flags);
    groupData(parsedDataHoldingResponse, groupByCategoryHolding);
  };

  const groupData = (dataHolding: Holding, groupByCategoryHolding: CategoryData[]) => {
    const groupByScope = [
      { scope: 1, total_emissions: 0 },
      { scope: 2, total_emissions: 0 },
      { scope: 3, total_emissions: 0 }
    ];
    const orgs: OrganizationShort[] = [
      {
        id: organizationFound?.id ?? '',
        company_name: organizationFound?.company_name ?? ''
      }
    ];

    let co2 = 0;

    dataHolding.childs_info.forEach((child) => {
      orgs.push({
        id: child.org_id,
        company_name: child.org_name
      });
      child.co2e.forEach((elem) => {
        co2 += elem.total_emissions;
        if (elem.scope === 1) {
          groupByScope[0].total_emissions += elem.total_emissions;
        }
        if (elem.scope === 2) {
          groupByScope[1].total_emissions += elem.total_emissions;
        }
        if (elem.scope === 3) {
          groupByScope[2].total_emissions += elem.total_emissions;
        }

        let category = elem.category;
        if (category === 'passenger' || category === 'freight') {
          category = 'transport';
        }

        const foundCategoryIndex = groupByCategoryHolding.findIndex(
          (elem2) => elem2.category === category
        );
        if (foundCategoryIndex !== -1) {
          groupByCategoryHolding[foundCategoryIndex].total_emissions += elem.total_emissions;
        } else {
          groupByCategoryHolding.push({
            category: category,
            total_emissions: elem.total_emissions,
            scope: elem.scope
          });
        }
      });
    });

    dataHolding.parent_co2e?.forEach((elem) => {
      co2 += elem.total_emissions;
      if (elem.scope === 1) {
        groupByScope[0].total_emissions += elem.total_emissions;
      }
      if (elem.scope === 2) {
        groupByScope[1].total_emissions += elem.total_emissions;
      }
      if (elem.scope === 3) {
        groupByScope[2].total_emissions += elem.total_emissions;
      }

      let category = elem.category;
      if (category === 'passenger' || category === 'freight') {
        category = 'transport';
      }
      const foundCategoryIndex = groupByCategoryHolding.findIndex(
        (elem2) => elem2.category === category
      );

      if (foundCategoryIndex !== -1) {
        groupByCategoryHolding[foundCategoryIndex].total_emissions += elem.total_emissions;
      } else {
        groupByCategoryHolding.push({
          category: category,
          total_emissions: elem.total_emissions,
          scope: elem.scope
        });
      }
    });

    setDataScopes(groupByScope);
    setDataCategories(groupByCategoryHolding);
    setTotalCo2(co2);
  };

  return {
    loading,
    dataScopes,
    dataCategories,
    totalCo2,
    disabled,
    dataHolding,
    dataYearComparison
  };
};
