import {
  NuvoImporter as NuvoImporterOriginal,
  OnResults,
  ProcessorType,
  SettingsAPI,
  type ResultValues
} from 'nuvo-react';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { NUVO_STYLE, treatLine } from '../../../utils/nuvo';

type Props = Omit<ComponentProps<typeof NuvoImporterOriginal>, 'licenseKey'> & {
  lookAndFeel?: 'primary' | 'secondary' | 'link';
  btnI18nKey?: string;
  namespace?: string;
};

const nuvoAdapter = (results: ResultValues) => {
  return results.map(treatLine);
};

const NuvoImporter = ({ lookAndFeel = 'primary', btnI18nKey, namespace, ...props }: Props) => {
  const { t } = useTranslation(namespace);
  const { t: globalT } = useTranslation();

  if (
    !process.env.REACT_APP_NUVO_API_KEY ||
    !process.env.REACT_APP_NUVO_DEV_API_KEY ||
    !process.env.REACT_APP_ENVIRONMENT
  )
    return null;

  const settings: SettingsAPI = {
    ...props.settings,
    processingEngine: 'node' as ProcessorType,
    developerMode: process.env.REACT_APP_ENVIRONMENT !== 'prod',
    i18nOverrides: {
      ...props.settings?.i18nOverrides,
      txt_positive_button_close_modal: globalT('nuvo.txt_positive_button_close_modal'),
      txt_download_excel: globalT('nuvo.txt_download_excel'),
      txt_download_csv: globalT('nuvo.txt_download_csv'),
      txt_complete_imports: globalT('nuvo.txt_complete_imports'),
      txt_import_data: btnI18nKey ? t(btnI18nKey) : globalT('nuvo.txt_import_data')
    },
    style: { ...props.settings.style, ...NUVO_STYLE[lookAndFeel] }
  };

  const handleResults: OnResults = (results: ResultValues, errors, complete, logs) => {
    const adaptedResults = nuvoAdapter(results);

    return props.onResults?.(adaptedResults, errors, complete, logs);
  };

  const rest = {
    ...props,
    licenseKey:
      process.env.REACT_APP_ENVIRONMENT === 'prod'
        ? process.env.REACT_APP_NUVO_API_KEY
        : process.env.REACT_APP_NUVO_DEV_API_KEY,
    settings,
    onResults: handleResults
  };

  return <NuvoImporterOriginal {...rest} />;
};

export default NuvoImporter;
