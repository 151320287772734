import React from 'react';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import Button from '../../../../../../ui/button/Button';

type Props = {
  handleClose: () => void;
};
const ConfigureFrameworksModal = ({ handleClose }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.metrics.configureFrameworksModal'
  });
  return (
    <div>
      <FormHeader title={t('title')} description={t('description')} />
      <div>
        <img
          src='/images/configureFrameworks.svg'
          alt='configureFrameworks'
          style={{ width: '100%' }}
        />
      </div>
      <FormButtonSection>
        <Button lookAndFeel='primary' onClick={handleClose} text={t('save')} />
      </FormButtonSection>
    </div>
  );
};

export default ConfigureFrameworksModal;
