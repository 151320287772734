import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUnknownVehicles, getVehicleFuels } from '../../../../../services/api/vehicle';

type dataVehicle = {
  id: string;
  name: string;
  total: number;
  type: string;
};

export const useFilters = () => {
  const { t } = useTranslation();
  const [unknownVehicles, setUnknownVehicles] = useState({});
  const [fuelVehicles, setFuelVehicles] = useState({});

  const fetchUnknownVehicles = async () => {
    const data = await getUnknownVehicles();
    if (!data) return;

    const transformData = data.map((vehicle: dataVehicle) => ({
      id: vehicle.type,
      name: t(`vehicles.${vehicle.type}`),
      total: undefined,
      type: 'unknown_vehicle_type'
    }));

    setUnknownVehicles(transformData);
  };

  const fetchVehicleFuels = async () => {
    const data = await getVehicleFuels();
    if (!data) return;

    const transformData = data.map((fuel: any) => ({
      id: fuel.fuel,
      name: t(`vehicle_fuels.${fuel.fuel}`),
      total: undefined,
      type: 'vehicle_fuel'
    }));

    setFuelVehicles(transformData);
  };

  const ownership = [
    {
      id: 'rented',
      name: t('vehicles.rented'),
      total: undefined,
      type: 'ownership'
    },
    {
      id: 'owned',
      name: t('vehicles.owned'),
      total: undefined,
      type: 'ownership'
    }
  ];

  useEffect(() => {
    fetchUnknownVehicles();
    fetchVehicleFuels();
  }, []);

  return { ownership, unknownVehicles, fuelVehicles };
};
