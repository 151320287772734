import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../dashboardCommon/card/Card';
import { PieChart } from 'react-minimal-pie-chart';
import {
  dark_blue,
  dark_purple,
  darkest_blue,
  darkest_purple,
  scope1_color,
  scope3_color
} from '../../../../../styles/colors';
import Dot from '../../../../ui/dot/Dot';
import PieChartCustom from '../../../../ui/pieChartCustom/PieChartCustom';

const DataSource = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.dataSource'
  });

  const pieChartData = [
    { title: t('manual'), value: 40, color: darkest_blue, key: 'manual' },
    { title: t('forms'), value: 20, color: dark_blue, key: 'forms' },
    { title: t('files'), value: 15, color: scope1_color, key: 'files' },
    { title: t('integration'), value: 10, color: darkest_purple, key: 'integration' },
    { title: t('dcycleAI'), value: 10, color: dark_purple, key: 'dcycleAI' },
    { title: t('datadis'), value: 5, color: scope3_color, key: 'datadis' }
  ];

  const total = pieChartData.filter((elem) => elem.value > 0).length;

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <div className='flex gap-2 items-center'>
          <div className='flex-col gap-2'>
            {pieChartData.map((elem) => (
              <div key={elem.key} className='flex gap-1 items-center'>
                <Dot color={elem.color} type='custom' />
                <span className='font-12 weight-600'>{elem.title}</span>
              </div>
            ))}
          </div>
          <PieChartCustom
            data={pieChartData}
            total={total}
            unit={t('sources')}
            viewBoxSize={[100, 100]}
            lineWidth={24}
            totalValue={100}
            rounded
            animate
            width={'8rem'}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default DataSource;
