import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { authLogin } from '../../services/authService';
import Button from '../ui/button/Button';
import './styles.scss';
import controller from '../../environments/controller';

const environment = controller();

const Onboarding = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSignIn = () => {
    authLogin();
  };

  const sendToLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <section className='onboarding'>
      <div className='onboarding-section'>
        <img className='onboarding-section__logo' src='/images/logoBlack.svg' alt='logo' />
        <div className='onboarding-section__header'>
          <h1 className='headline1-font on-light-text-color'>
            {t('onboarding.title') + ' DELIGHT'}
          </h1>
          <h3 className='subtitle1-font on-light-text-color'>{t('onboarding.subtitle')}</h3>
        </div>
        <div className='onboarding-section__onboarding-form'>
          <Button
            lookAndFeel='primary'
            text={t('onboarding.createAccount')}
            onClick={handleSignIn}
          />
          <div className='flex divider'>
            <div className='divider__line'></div>
            <span className='span-font on-light-text-color'>{t('onboarding.or')}</span>
            <div className='divider__line'></div>
          </div>
          <div className='onboarding-section__onboarding-form__buttons'>
            <Button
              lookAndFeel='secondary'
              onClick={() => {
                //
              }}
              text={t('onboarding.registerGoogle')}
              icon='/images/googleLogo.svg'
            />
          </div>

          <div className='flex onboarding-section__onboarding-form__footer'>
            <span className='span-font on-light-text-color'>
              {t('onboarding.alreadyHaveAccount')}
            </span>
            <span className='span-font highlight-text-color' onClick={sendToLogin}>
              {t('onboarding.access')}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Onboarding;
