import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EsgFrameworkCompleteness } from '../../../../../types/entities/esgMetrics';
import LoaderCard from '../../../../ui/loaders/loaderCard/LoaderCard';
import Card from '../../../dashboardCommon/card/Card';
import FrameworkProgressBar from './components/FrameworkProgressBar';
import Button from 'components/ui/button/Button';
import ConfigureFrameworksModal from '../metrics/components/configureFrameworksModal/ConfigureFrameworksModal';
import Modal from 'components/ui/modal/Modal';

type Props = {
  data: EsgFrameworkCompleteness[];
  loading: boolean;
};
const FrameworkProgress = ({ data, loading }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'controlPanel.frameworkProgress'
  });

  const [extendedData, setExtendedData] = useState<EsgFrameworkCompleteness[]>([]);

  // qwerty for webinar
  const [showConfigureFrameworksModal, setShowConfigureFrameworksModal] = useState(false);
  useEffect(() => {
    if (!loading && data) {
      const ghgProtocolEntry = data.find((item) => item.framework === 'GHG_PROTOCOL');
      setExtendedData([
        ...data,
        {
          framework: 'SBTIs',
          total: ghgProtocolEntry ? ghgProtocolEntry.total : 100,
          completed: ghgProtocolEntry ? ghgProtocolEntry.completed - 0.1 : 100
        },
        {
          framework: 'ISO_14001',
          total: 100,
          completed: 50
        },
        {
          framework: 'MITECO',
          total: ghgProtocolEntry ? ghgProtocolEntry.total : 100,
          completed: ghgProtocolEntry ? ghgProtocolEntry.completed : 100
        },
        {
          framework: 'CDP_Climate',
          total: 100,
          completed: 70,
          pro: true
        }
      ]);
    }
  }, [loading, data]);
  if (loading) {
    return <LoaderCard />;
  }
  return (
    <Card
      style={{
        gridTemplateRows: '4rem 1fr'
      }}>
      <Card.Header
        title={t('title')}
        style={{
          alignItems: 'center',
          marginBottom: '1rem'
        }}>
        <Button
          style={{ whiteSpace: 'nowrap', width: 'fit-content' }}
          lookAndFeel='primary'
          size='small'
          onClick={() => setShowConfigureFrameworksModal(true)}>
          {t('configureFrameworks')}
        </Button>
      </Card.Header>
      <Card.Body>
        <div
          className='on-card-gray-bg-color pt-4 pb-4 pr-6 pl-6 flex flex-col gap-4 rounded-8'
          style={{ height: '8.5rem', overflowY: 'auto' }}>
          {extendedData.map((elem) => (
            <FrameworkProgressBar
              key={elem.framework}
              title={elem.framework.replaceAll('_', ' ')}
              total={elem.total}
              completed={elem.framework === 'CSRD' ? elem.completed + 0.9 : elem.completed}
              uncertainty={0}
              pro={elem.pro}
            />
          ))}
        </div>
      </Card.Body>
      {/* qwerty for webinar */}
      <Modal.WithPortal
        show={showConfigureFrameworksModal}
        onClose={() => setShowConfigureFrameworksModal(false)}
        width='756px'
        maxWidth='756px'>
        <ConfigureFrameworksModal handleClose={() => setShowConfigureFrameworksModal(false)} />
      </Modal.WithPortal>
    </Card>
  );
};

export default FrameworkProgress;
