import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUnknownVehicles } from '../../../../../services/api/vehicle';

export const useVehiclesTypes = () => {
  const { t } = useTranslation();
  const [vehiclesTypes, setVehiclesTypes] = useState<any>([]);

  const fetchUnknownVehicles = async () => {
    const data = await getUnknownVehicles();
    if (!data) return;

    const transformData = data.map((vehicle: any) => ({
      label: t(`vehicles.${vehicle.type}`),
      value: vehicle.id,
      type: 'string'
    }));

    setVehiclesTypes(transformData);
  };

  useEffect(() => {
    fetchUnknownVehicles();
  }, []);

  return vehiclesTypes;
};
