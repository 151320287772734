import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../ui/button/Button';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  total: number;
  completed: number;
  route: string;
};
const DataCategoryCTA = ({ title, total, completed, route }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'controlPanel.dataProgress' });

  const navigate = useNavigate();
  return (
    <div className='flex items-center gap-1 justify-between'>
      <div className='flex flex-col'>
        <span className='font-12 weight-600'>{title}</span>
        <span>
          <span className='font-12 weight-600 highlight-text-color'>
            {t('dataCompleted', {
              completed,
              total
            })}
          </span>{' '}
          <span className='font-12 weight-400'>{t('withData')}</span>
        </span>
      </div>
      <Button
        lookAndFeel='secondary'
        text={t('complete')}
        onClick={() => {
          navigate(route);
        }}
        size='small'
        style={{ width: 'fit-content' }}
      />
    </div>
  );
};

export default DataCategoryCTA;
