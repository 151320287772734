import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../../context/userContext';
import { getVehicleFuels } from '../../../../../services/api/vehicle';

const useFuels = () => {
  const user = useContext(UserContext);
  const [fuels, setFuels] = useState<{ id: string; name: string; value: string }[]>([]);

  const [selectedFuel, setSelectedFuel] = useState<SelectOptionFormat>({
    id: '',
    name: ''
  });
  const [loading, setLoading] = useState(false);

  const loadFuels = async () => {
    if (!user?.selectedOrganization || loading) return;

    setLoading(true);
    const response = await getVehicleFuels('ES');

    if (response?.response?.status >= 400) return;

    const fuelsFormatted = response.map((fuel: any) => ({
      id: fuel.id,
      name: t(`vehicle_fuels.${fuel.fuel}`),
      value: fuel.fuel
    }));

    setFuels(fuelsFormatted);
    setSelectedFuel({
      id: '',
      name: ''
    });

    setLoading(false);
  };

  useEffect(() => {
    loadFuels();
  }, []);

  const handleSelectFuel = (fuel: SelectOptionFormat) => setSelectedFuel(fuel);

  return {
    fuels,
    selectedFuel,
    handleSelectFuel,
    loading
  };
};

export default useFuels;
